<template>
  <div class="relative sm:pt-5 sm:pb-24">
    <section class="flex gap-x-5 md:gap-y-10 px-2 sm:px-4">

      <!-- Filtreleme Alanı -->
      <div class="w-80" v-if="isShowFilter" >
        <slot name="filter"/>
      </div>

      <div class="relative" :class="[isShowFilter ? 'w-full' : 'flex-1 w-full']">

        <div class="flex justify-between mb-2.5 sm:-ml-5" v-if="!isHideBreadCrumbs">
          <slot name="breadcrumbs" />
        </div>

        <!-- Header -->
        <slot name="header" />

        <!-- Ürün listeleme bölümü -->
        <ClientOnly>
          <div class="overflow-y-auto">
            <TemplatesListingCategoryHorizontalDefault />
          </div>
        </ClientOnly>

        <slot name="emptyArea" />

        <slot />

      </div>
    </section>

    <!-- HTML Açıklama Alanı -->
    <slot name="htmlContentArea" />
  </div>
</template>

<script setup>
const storeConfig = useStoreConfig()
const {currency} = storeToRefs(storeConfig)

const {isShowFilter, isHideBreadCrumbs} = useHelper()

const {items, currentPage, perPage, totalResults, totalPage, baseParams, nextPage, prevPage, orderBy} = usePagination()
const {isDouble} = useListing()
const mobileListingType = useState('mobileListingType', () => isDouble.value ? 2 : 1)

const mobileWrapperClass = computed(() => {
  return mobileListingType.value === 2 ? 'grid-cols-2' : 'grid-cols-1'
})
</script>